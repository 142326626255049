<script>
import ConfirmPhone from '@/components/ConfirmPhone.vue';

export default {
  name: 'Authorization',
  components: {
    ConfirmPhone,
  },
  data() {
    return {
      showModalConfirmPhone: true,
    }
  },
}
</script>

<template>
  <div v-if="!showModalConfirmPhone">
    <h3 class="text-light-main dark:text-dark-main text-xl font-bold mb-2 pt-5">Чтобы продолжить необходимо
      авторизоваться</h3>
    <Btn @click="showModalConfirmPhone = true" class="mb-3 mt-5">Авторизоваться</Btn>
  </div>

  <ConfirmPhone :modalShow="showModalConfirmPhone" :redirect="true" @update:modalShow="showModalConfirmPhone = $event"></ConfirmPhone>
</template>
