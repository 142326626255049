<script>
import Btn from '@/components/UI/Btn.vue';
import Input from '@/components/UI/Input.vue';
import ConfirmPhone from '@/components/ConfirmPhone.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: { Btn, Input, ConfirmPhone },
  name: "AddAddress",
  data() {
    return {
      city: '',
      street: '',
      error: '',
      showModalConfirmPhone: false,
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer.customer,
    }),
  },
  methods: {
    ...mapActions(['fetchCustomer']),
    async submitAddress() {
      if (!this.customer) {
        this.showModalConfirmPhone = true;
      } else {
        try {
          this.error = '';
          const url = process.env.VUE_APP_BASE_V1_URL + '/address';
          const userId = localStorage.getItem('authUserId');
          const userKey = localStorage.getItem('authUserKey');
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'User-id': userId,
              'User-key': userKey
            },
            body: JSON.stringify({
              city: this.city,
              info: this.street,
            }),
          });

          if (!response.ok) {
            const responseData = await response.json();
            this.error = responseData.message;
            throw new Error('Failed to add address');
          }
          console.log('Address added successfully!');
          this.$router.go(-1);
        } catch (error) {
          console.error('Error adding address:', error);
        }
      }
    },
  },
  mounted() {
    if (!this.customer) {
      this.fetchCustomer();
    }
  }
};
</script>

<template>
  <div>
    <div class="relative">
      <h3 class="text-center py-[29px] text-light-main dark:text-dark-main font-bold text-xl leading-6">Новый адрес</h3>
      <p v-show="error" class="text-center text-attention font-medium text-sm mt-1 mb-4">
        *Произошла ошибка:
        {{ error }}
      </p>
      <button @click="$router.go(-1)"
        class="absolute right-0 top-1/2 translate-y-[-50%] w-10 h-10 flex items-center justify-center rounded-xl bg-light-gray-20">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="dark:stroke-dark-main" d="M11 1.00004L1.00004 11M1 1L11 11" stroke="#131313" stroke-width="1.5"
            stroke-linecap="round" />
        </svg>
      </button>
    </div>
    <div class="rounded-3xl bg-light-overlay dark:bg-transparent p-4 flex flex-col gap-3 mb-5">
      <Input label="Город" v-model:value="city" />
      <Input label="Улица, кв/дом" v-model:value="street" />
    </div>
    <Btn :disabled="!city || !street" @click="submitAddress">Добавить</Btn>
    <ConfirmPhone :modalShow="showModalConfirmPhone" @update:modalShow="showModalConfirmPhone = $event"></ConfirmPhone>
  </div>
</template>