import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Filter from '../views/Filter.vue'
import Basket from '../views/basket/Index.vue'
import Profile from '../views/profile/Index.vue'
import Info from '../views/profile/Info.vue'
import Address from '../views/profile/Address.vue'
import Receiver from '../views/profile/Receiver.vue'
import Transaction from '../views/profile/Transaction.vue'
import Order from '../views/order/Index.vue'
import Unpaid from '../views/order/Unpaid.vue'
import OrderId from '../views/order/Id.vue'
import OrderStory from '../views/order/Story.vue'
import OrderPayment from '../views/order/Payment.vue'
import OrderCreated from '../views/order/Created.vue'
import Support from '../views/support/Index.vue'
import SupportRequest from '../views/support/request/Index.vue'
import SupportRequestId from '../views/support/request/Id.vue'
import Payment from '../views/Payment.vue'
import About from '../views/About.vue'
import Guarantee from '../views/Guarantee.vue'
import Compensation from '../views/Compensation.vue'
import Authorization from '../views/Authorization.vue'
import OfferAgreement from '../views/OfferAgreement.vue'
import AddAddress from '../views/AddAddress.vue'
import AddReceiver from '../views/AddReceiver.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/filter',
    name: 'filter',
    component: Filter
  },
  {
    path: '/basket',
    name: 'basket',
    component: Basket
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/profile/info',
    name: 'profile-info',
    component: Info
  },
  {
    path: '/profile/address',
    name: 'profile-address',
    component: Address
  },
  {
    path: '/profile/receiver',
    name: 'profile-receiver',
    component: Receiver
  },
  {
    path: '/profile/transaction',
    name: 'profile-transaction',
    component: Transaction
  },
  {
    path: '/order',
    name: 'order',
    component: Order
  },
  {
    path: '/order/unpaid',
    name: 'order-unpaid',
    component: Unpaid
  },
  {
    path: '/order/:id',
    name: 'order-id',
    component: OrderId,
  },
  {
    path: '/order/:id/story',
    name: 'order-story',
    component: OrderStory
  },
  {
    path: '/order/payment',
    name: 'order-payment',
    component: OrderPayment,
  },
  {
    path: '/order/created/:id',
    name: 'order-created',
    component: OrderCreated,
  },
  {
    path: '/support',
    name: 'support',
    component: Support
  },
  {
    path: '/support/request',
    name: 'support-request',
    component: SupportRequest
  },
  {
    path: '/support/request/:id',
    name: 'support-request-id',
    component: SupportRequestId,
    props: true
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/guarantee',
    name: 'guarantee',
    component: Guarantee
  },
  {
    path: '/сompensation',
    name: 'сompensation',
    component: Compensation
  },
  {
    path: '/authorization',
    name: 'authorization',
    component: Authorization
  },
  {
    path: '/offerAgreement',
    name: 'offerAgreement',
    component: OfferAgreement
  },
  {
    path: '/add-address',
    name: 'add-address',
    component: AddAddress
  },
  {
    path: '/add-receiver',
    name: 'add-receiver',
    component: AddReceiver
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
