const state = {
    customer: null,
    isLoading: false,
    error: null,
};

const mutations = {
    SET_CUSTOMER(state, customer) {
        state.customer = customer;
    },
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

const getters = {
    getCustomer(state) {
        return state.customer;
    },
};

const actions = {
    async fetchCustomer({
        commit
    }) {
        commit('SET_LOADING', true);

        try {
            const userId = localStorage.getItem('authUserId');
            const userKey = localStorage.getItem('authUserKey');
            const url = process.env.VUE_APP_BASE_V1_URL + '/customer';

            const response = await fetch(url, {
                headers: {
                    'User-id': userId,
                    'User-key': userKey
                }
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }
            const customerData = await response.json();

            commit('SET_CUSTOMER', customerData);
        } catch (error) {
            commit('SET_ERROR', error.message);
            console.error('Error fetching customer:', error);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    setCustomer({
        commit
    }, customer) {
        commit('SET_CUSTOMER', customer); // Commit mutation with processed data
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};