<script>
import VueQrcode from 'vue-qrcode';
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        VueQrcode
    },
    name: "Payment",
    computed: {
        ...mapState({
            customer: (state) => state.customer.customer,
        }),
    },
    created() {
        const authUserId = localStorage.getItem('authUserId');
        if (!authUserId) {
            this.$router.push({ name: 'authorization' });
        }
    },
}
</script>

<template>
    <div class="py-[20px] mb-[24px]">
        <h6 class="font-gilroy font-bold text-[32px] leading-[38px] text-light-main dark:text-dark-main">
            Пополнить счет
        </h6>
    </div>
    <div v-if="customer" class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px]">
        <div class="h-[273px] flex items-center justify-center mb-[66px] dark:bg-dark-main dark:rounded-3xl">
            <!-- <img src="@/assets/images/qr-code.svg" alt=""> -->
            <vue-qrcode :value="customer.wallet" class="w-full" />
        </div>
        
        <div>
            <CopyBtn :code="customer.wallet" />
            <p class="font-gilroy font-medium text-[12px] leading-[14.12px] text-attention">
                Внимание, к оплате принимаются только токены USDT на TRC-20
            </p>
        </div>
    </div>
</template>
